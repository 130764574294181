<template>
  <v-container>
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('report.activity.orderProcessingTime.table.title') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="days" :loading="loading">
            <template #[`body.prepend`]="{items}">
              <tr>
                <th class="text-center d-block d-sm-table-cell">
                  {{ $t('report.activity.orderProcessingTime.table.column.total') }}
                </th>
                <th class="text-center d-block d-sm-table-cell" />
                <th class="text-center d-block d-sm-table-cell">
                  {{ minTime(items) }}
                </th>
                <th class="text-center d-block d-sm-table-cell">
                  {{ avgTime(items) }}
                </th>
                <th class="text-center d-block d-sm-table-cell">
                  {{ maxTime(items) }}
                </th>
              </tr>
            </template>
            <template #[`item.min`]="{ item }">
              {{ secondToHHmm(item.min) }}
            </template>
            <template #[`item.avg`]="{ item }">
              {{ secondToHHmm(item.avg) }}
            </template>
            <template #[`item.max`]="{ item }">
              {{ secondToHHmm(item.max) }}
            </template>
            <template #[`item.date`]="{ item }">
              {{ formatDate(item.date) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { displayDateISO, displayWeekday, getFirstDayOfMonthDate, getTodayDate, secondToHHmm } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';

  export default {
    name: 'LocationOrderProcessingTime',
    components: { PeriodSelector },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        loading: false,
        headers: [
          {
            text: this.$i18n.t('report.activity.orderProcessingTime.table.column.date'),
            align: 'start',
            filterable: false,
            sortable: true,
            value: 'date'
          },
          {
            text: this.$i18n.t('report.activity.orderProcessingTime.table.column.day'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'day'
          },
          {
            text: this.$i18n.t('report.activity.orderProcessingTime.table.column.minimum'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'min'
          },
          {
            text: this.$i18n.t('report.activity.orderProcessingTime.table.column.average'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'avg'
          },
          {
            text: this.$i18n.t('report.activity.orderProcessingTime.table.column.maximum'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'max'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['date'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        days: [],
        total: 0
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      formatDate: displayDateISO,
      minTime (items) {
        const values = items.filter((item) => item.min > 0);
        if (values.length === 0) {
          return '';
        }
        const min = Math.min(...values.map((value) => value.min));
        return min === 0 ? '' : secondToHHmm(min);
      },
      avgTime (items) {
        const values = items.filter((item) => item.avg > 0);
        const total = values.reduce((total, item) => total + item.avg, 0);
        const counter = values.reduce((count, item) => count + (item.avg > 0 ? 1 : 0), 0);

        return counter === 0 ? '' : secondToHHmm(total / counter);
      },
      maxTime (items) {
        const values = items.filter((item) => item.max > 0);
        if (values.length === 0) {
          return '';
        }
        const max = Math.max(...values.map((value) => value.max));
        return max === 0 ? '' : secondToHHmm(max);
      },
      secondToHHmm: secondToHHmm,
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.loading) {
          return;
        }

        this.loading = true;
        try {
          const fromDate = encodeURIComponent(this.startFullDate);
          const toDate = encodeURIComponent(this.endFullDate);
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/location/orderProcessingTime?from=' + fromDate + '&to=' + toDate, { timeout: 60000 });
          if (response.data !== undefined && response.data.days !== undefined) {
            this.days = response.data.days;
            this.days.forEach((item) => {
              item.day = displayWeekday(item.date);
            });
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('report.error'));
        }
        this.loading = false;
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
