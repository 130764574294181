<template>
  <v-container>
    <div v-if="Array.isArray(todoList) && todoList.length > 0">
      <h2>{{ $t('report.activity.productionTasksPerformed.subtitle.todo') }}</h2>
      <div v-for="entry in todoList" :key="entry.user.id">
        <h4>{{ entry.user.firstName }} {{ entry.user.lastName }}</h4>
        <production-tasks-view :tasks="entry.tasks" />
      </div>
    </div>
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-card id="table-tasks-performed">
      <v-card-title>
        {{ $t('report.activity.productionTasksPerformed.subtitle.history') }}
        <v-spacer />
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('report.activity.productionTasksPerformed.search')" single-line hide-details />
        <v-spacer />
        <table-exporter :start-full-date="startFullDate" :end-full-date="endFullDate" :headers="headers" :items="tasksPerformedFormatted" />
        <table-printer element-id="table-tasks-performed" />
      </v-card-title>
      <v-data-table :headers="headers" :items="tasksPerformed" :options="options" :search="search" :loading="loading" hide-default-footer>
        <template #[`item.type`]="{ item }">
          <v-icon>
            mdi-{{ getIcon(item) }}
          </v-icon>
          {{ $t('report.activity.productionTasksPerformed.type.' + item.task.type) }}
        </template>
        <template #[`item.user`]="{ item }">
          {{ item.user.firstName }} {{ item.user.lastName }}
        </template>
        <template #[`item.executors`]="{ item }">
          {{ Array.isArray(item.executors) ? item.executors.join(', ') : item.executors }}
        </template>
        <template #[`item.state`]="{ item }">
          <v-icon :color="COLORS[item.state]">
            mdi-{{ ICONS[item.state] }}
          </v-icon>
          <span :style="'font-weight:bolder;color:' + COLORS[item.state]">{{ $t('report.activity.productionTasksPerformed.state.' + item.state) }}</span>
        </template>
        <template #[`item.delay`]="{ item }">
          {{ formatDelay(item) }}
        </template>
        <template #[`item.startedAt`]="{ item }">
          {{ formatDate(item.startedAt) }}
        </template>
      </v-data-table>
    </v-card>
    <div v-if="Object.keys(taskDistributionFormatted).length > 0">
      <h1>{{ $t('report.activity.productionTasksPerformed.taskDistribution.title') }}</h1>
      <bar-chart :chart-data="taskDistributionFormatted" :options="barChartOptions" data-position="below" data-color="rgba(180, 220, 220, 1)" />
    </div>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { displayDateISO, displayHourISO, getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import BarChart from '@/views/components/common/chart/BarChart';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';
  import ProductionTasksView from '@/views/components/business/productionTask/ProductionTasksView';

  export default {
    name: 'ProductionTasksPerformedAnalytics',
    components: { BarChart, ProductionTasksView, PeriodSelector, TableExporter, TablePrinter },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        COLORS_TYPE: { date: '#f1c40f', cleaning: '#26c6da', cooking: '#2ecc71', placing: '#e67e22', stock: '#9b59b6' },
        COLORS: { done: 'green', skip: 'blue', undone: 'red' },
        ICONS: { done: 'check', skip: 'skip-next', undone: 'close' },
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        search: '',
        headers: [{
          text: this.$t('report.activity.productionTasksPerformed.column.type'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'type'
        }, {
          text: this.$t('report.activity.productionTasksPerformed.column.task'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'task.name'
        }, {
          text: this.$t('report.activity.productionTasksPerformed.column.user'),
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'user'
        }, {
          text: this.$t('report.activity.productionTasksPerformed.column.executors'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'executors'
        }, {
          text: this.$t('report.activity.productionTasksPerformed.column.state'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'state'
        }, {
          //   text: this.$t('report.activity.productionTasksPerformed.column.delay'),
          //   align: 'center',
          //   filterable: false,
          //   sortable: false,
          //   value: 'delay'
          // }, {
          text: this.$t('report.activity.productionTasksPerformed.column.startedAt'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'startedAt'
        }],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['startedAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false
        },
        icons: { date: 'calendar', cleaning: 'broom', cooking: 'chef-hat', placing: 'food-fork-drink', stock: 'wardrobe' },
        barChartOptions: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom',
            align: 'center'
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                min: 0
              }
            }]
          }
        },
        todoList: [],
        tasksPerformed: [],
        tasksPerformedFormatted: [],
        taskDistribution: [],
        taskDistributionFormatted: {}
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      getIcon (item) {
        if (this.icons[item.task.type]) {
          return this.icons[item.task.type];
        }

        return 'help-circle';
      },
      formatDate (date) {
        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      formatDelay (item) {
        const diff = DateTime.fromISO('2000-01-01T' + DateTime.fromISO(item.createdAt).setZone('Europe/Paris').toFormat('HH:mm:ss') + 'Z').diff(
          DateTime.fromISO('2000-01-01T' + item.task.from + 'Z'), ['hours', 'minutes', 'seconds']);

        return diff.toObject().hours + ':' + diff.toObject().minutes + ':' + diff.toObject().seconds;
      },
      async refreshData () {
        this.loading = true;
        try {
          const fromDate = encodeURIComponent(this.startFullDate);
          const toDate = encodeURIComponent(this.endFullDate);
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'production/task/performed?from=' + fromDate + '&to=' + toDate, { timeout: 60000 });
          if (response.data !== undefined && response.data.tasksPerformed !== undefined) {
            this.tasksPerformed = response.data.tasksPerformed;
            this.todoList = response.data.todo;
            this.taskDistribution = response.data.statistics;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('common.error.default'));
        }
        this.loading = false;

        this.tasksPerformedFormatted = this.tasksPerformed.map((performed) => {
          return {
            task: performed.task.name,
            user: performed.user.firstName + ' ' + performed.user.lastName,
            executors: performed.executors.join(', '),
            state: this.$t('report.activity.productionTasksPerformed.state.' + performed.state),
            delay: this.formatDelay(performed),
            startedAt: this.formatDate(performed.startedAt)
          };
        });
        this.taskDistributionFormatted = { labels: [], datasets: [] };
        this.taskDistributionFormatted.labels = Object.keys(this.taskDistribution.executors);
        const dataByType = {};
        Object.keys(this.taskDistribution.executors).forEach((executor) => {
          Object.keys(this.taskDistribution.executors[executor]).forEach((type) => {
            if (dataByType[type] === undefined) {
              dataByType[type] = [];
            }
            dataByType[type].push(this.taskDistribution.executors[executor][type].count);
          });
        });
        this.taskDistributionFormatted.datasets = Object.keys(dataByType).map((type) => {
          return { label: this.$t('report.activity.productionTasksPerformed.type.' + type), backgroundColor: this.COLORS_TYPE[type], stack: 'period', data: dataByType[type] };
        });
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
