<template>
  <v-container>
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('report.activity.distributors.title') }}
          </v-card-title>
          <v-data-table :mobile-breakpoint="0" :headers="headers" :items="distributors" :options="options" :loading="loading" hide-default-footer fixed-header height="600px">
            <template #[`body.prepend`]="{items}">
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.activity.distributors.table.total') }}
                </th>
                <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                  {{ displayTotal(header.value, items) }}
                </th>
              </tr>
              <tr>
                <th class="text-uppercase">
                  {{ $t('report.activity.distributors.table.avg') }}
                </th>
                <th v-for="header in headers.slice(1)" :key="header.value" class="text-center">
                  {{ displayAvg(header.value, items) }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';

  export default {
    name: 'DistributorsAnalytics',
    components: { PeriodSelector },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        loading: false,
        headers: [],
        distributors: [],
        ranking: {},
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.loading) {
          return;
        }

        this.loading = true;
        try {
          const fromDate = encodeURIComponent(this.startFullDate);
          const toDate = encodeURIComponent(this.endFullDate);
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/distributors?from=' + fromDate + '&to=' + toDate, { timeout: 60000 });
          if (response.data !== undefined && response.data.distributors !== undefined) {
            this.distributors = [];
            this.setHeaders(response.data.distributors.labels);
            this.setDatasets(response.data.distributors.datasets);
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('report.error'));
        }
        this.loading = false;
      },
      setHeaders (labels) {
        let i = 0;
        this.headers = labels.map((label) => {
          return {
            text: label.title,
            align: i++ === 0 ? 'start' : 'center',
            filterable: false,
            sortable: false,
            value: label.id
          };
        });
      },
      setDatasets (datasets) {
        datasets.forEach((dataset) => {
          this.distributors.push(dataset);
        });
      },
      countDays (key, items) {
        return items.reduce((total, item) => total + (item[key] === undefined ? 0 : 1), 0);
      },
      totalDistributions (key, items) {
        return items.reduce((total, item) => total + (item[key] === undefined ? 0 : item[key]), 0);
      },
      avgDistributions (key, items) {
        const deliveries = this.totalDistributions(key, items);
        const services = this.countDays(key, items);

        return services > 0 ? deliveries / services : 0;
      },
      displayAvg (key, items) {
        const avg = this.avgDistributions(key, items);

        return avg > 0 ? String(avg.toFixed(2)) : '';
      },
      displayTotal (key, items) {
        const total = this.totalDistributions(key, items);

        return total > 0 ? String(total) : '';
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
