var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('period-selector',{attrs:{"start-date-prop":_vm.startDate,"start-hour-prop":_vm.startHour,"end-date-prop":_vm.endDate,"end-hour-prop":_vm.endHour,"loading":_vm.loading},on:{"update":_vm.updatePeriod}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('report.activity.orderProcessingTime.table.title'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.days,"loading":_vm.loading},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var items = ref.items;
return [_c('tr',[_c('th',{staticClass:"text-center d-block d-sm-table-cell"},[_vm._v(" "+_vm._s(_vm.$t('report.activity.orderProcessingTime.table.column.total'))+" ")]),_c('th',{staticClass:"text-center d-block d-sm-table-cell"}),_c('th',{staticClass:"text-center d-block d-sm-table-cell"},[_vm._v(" "+_vm._s(_vm.minTime(items))+" ")]),_c('th',{staticClass:"text-center d-block d-sm-table-cell"},[_vm._v(" "+_vm._s(_vm.avgTime(items))+" ")]),_c('th',{staticClass:"text-center d-block d-sm-table-cell"},[_vm._v(" "+_vm._s(_vm.maxTime(items))+" ")])])]}},{key:"item.min",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.secondToHHmm(item.min))+" ")]}},{key:"item.avg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.secondToHHmm(item.avg))+" ")]}},{key:"item.max",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.secondToHHmm(item.max))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }