var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(Array.isArray(_vm.todoList) && _vm.todoList.length > 0)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('report.activity.productionTasksPerformed.subtitle.todo')))]),_vm._l((_vm.todoList),function(entry){return _c('div',{key:entry.user.id},[_c('h4',[_vm._v(_vm._s(entry.user.firstName)+" "+_vm._s(entry.user.lastName))]),_c('production-tasks-view',{attrs:{"tasks":entry.tasks}})],1)})],2):_vm._e(),_c('period-selector',{attrs:{"start-date-prop":_vm.startDate,"start-hour-prop":_vm.startHour,"end-date-prop":_vm.endDate,"end-hour-prop":_vm.endHour,"loading":_vm.loading},on:{"update":_vm.updatePeriod}}),_c('v-card',{attrs:{"id":"table-tasks-performed"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('report.activity.productionTasksPerformed.subtitle.history'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('report.activity.productionTasksPerformed.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('table-exporter',{attrs:{"start-full-date":_vm.startFullDate,"end-full-date":_vm.endFullDate,"headers":_vm.headers,"items":_vm.tasksPerformedFormatted}}),_c('table-printer',{attrs:{"element-id":"table-tasks-performed"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tasksPerformed,"options":_vm.options,"search":_vm.search,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" mdi-"+_vm._s(_vm.getIcon(item))+" ")]),_vm._v(" "+_vm._s(_vm.$t('report.activity.productionTasksPerformed.type.' + item.task.type))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName)+" ")]}},{key:"item.executors",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Array.isArray(item.executors) ? item.executors.join(', ') : item.executors)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.COLORS[item.state]}},[_vm._v(" mdi-"+_vm._s(_vm.ICONS[item.state])+" ")]),_c('span',{style:('font-weight:bolder;color:' + _vm.COLORS[item.state])},[_vm._v(_vm._s(_vm.$t('report.activity.productionTasksPerformed.state.' + item.state)))])]}},{key:"item.delay",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDelay(item))+" ")]}},{key:"item.startedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startedAt))+" ")]}}],null,true)})],1),(Object.keys(_vm.taskDistributionFormatted).length > 0)?_c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t('report.activity.productionTasksPerformed.taskDistribution.title')))]),_c('bar-chart',{attrs:{"chart-data":_vm.taskDistributionFormatted,"options":_vm.barChartOptions,"data-position":"below","data-color":"rgba(180, 220, 220, 1)"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }