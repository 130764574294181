<template>
  <div v-if="Object.keys(datacollection).length > 0" class="smallChart">
    <bar-chart :chart-data="datacollection" :options="options" data-position="below" data-color="rgba(180, 220, 220, 1)" />
  </div>
  <div v-else>
    {{ $t('report.noresult') }}
  </div>
</template>

<script>
  import BarChart from '@/views/components/common/chart/BarChart';

  export default {
    name: 'CountOrdersAnalytics',
    components: {
      BarChart
    },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        datacollection: {},
        options: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom',
            align: 'center'
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                min: 0
              }
            }]
          }
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/order/week', { timeout: 60000 });
          if (response.data.labels !== undefined && response.data.datasets !== undefined) {
            this.datacollection = response.data;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('report.error'));
        }
      }
    }
  };
</script>
