<template>
  <v-container id="dashboard" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs v-model="tab" color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="onTabChange">
        <v-tab>{{ $t('report.activity.forecast.title') }}</v-tab>
        <v-tab>{{ $t('report.activity.orders.title') }}</v-tab>
        <v-tab>{{ $t('report.activity.crowd.title') }}</v-tab>
        <v-tab>{{ $t('report.activity.orderProcessingTime.title') }}</v-tab>
        <v-tab>{{ $t('report.activity.productionTasksPerformed.title') }}</v-tab>
        <v-tab>{{ $t('report.activity.drivers.title') }}</v-tab>
        <v-tab>{{ $t('report.activity.distributors.title') }}</v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshCurrentTab">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item key="1">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <location-forecast-analytics :refresh="refreshLocationForecast" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <count-orders-analytics :refresh="refreshOrders" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="3">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <location-crowd-analytics :refresh="refreshCrowd" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="4">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <location-order-processing-time :refresh="refreshOrderProcessingTime" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="5">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <production-tasks-performed-analytics :refresh="refreshTasksPerformed" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="6">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <drivers-analytics :refresh="refreshDrivers" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="7">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <distributors-analytics :refresh="refreshDistributors" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import CountOrdersAnalytics from '@/views/components/business/analytics/CountOrdersAnalytics';
  import DistributorsAnalytics from '@/views/components/business/analytics/DistributorsAnalytics';
  import DriversAnalytics from '@/views/components/business/analytics/DriversAnalytics';
  import LocationForecastAnalytics from '@/views/components/business/analytics/LocationForecastAnalytics';
  import LocationCrowdAnalytics from '@/views/components/business/analytics/LocationCrowdAnalytics';
  import LocationOrderProcessingTime from '@/views/components/business/analytics/LocationOrderProcessingTimeAnalytics';
  import ProductionTasksPerformedAnalytics from '@/views/components/business/analytics/ProductionTasksPerformedAnalytics';

  export default {
    name: 'Reports',
    components: { ProductionTasksPerformedAnalytics, AlertNotification, CountOrdersAnalytics, DistributorsAnalytics, DriversAnalytics, LocationCrowdAnalytics, LocationForecastAnalytics, LocationOrderProcessingTime },
    data () {
      return {
        alert: '',
        tab: null,
        currentTab: 0,
        loading: false,
        refreshDistributors: null,
        refreshDrivers: null,
        refreshCrowd: null,
        refreshLocationForecast: null,
        refreshOrderProcessingTime: null,
        refreshOrders: null,
        refreshTasksPerformed: null
      };
    },
    mounted () {},
    methods: {
      refreshCurrentTab () {
        this.onTabChange(this.currentTab);
      },
      onTabChange (tab) {
        switch (tab) {
          case 0:
            this.refreshLocationForecast = Date.now();
            break;
          case 1:
            this.refreshOrders = Date.now();
            break;
          case 2:
            this.refreshCrowd = Date.now();
            break;
          case 3:
            this.refreshOrderProcessingTime = Date.now();
            break;
          case 4:
            this.refreshTasksPerformed = Date.now();
            break;
          case 5:
            this.refreshDrivers = Date.now();
            break;
          case 6:
            this.refreshDistributors = Date.now();
            break;
        }
        this.loading = true;
        this.currentTab = tab;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
