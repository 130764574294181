<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t('report.activity.forecast.title') }}
      </v-card-title>
      <v-data-table :headers="headers" :items="forecasts" :loading="loading" hide-default-footer />
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'LocationForecastAnalytics',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        headers: [],
        forecasts: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/location/forecast', { timeout: 60000 });
          if (response.data !== undefined && response.data.forecast !== undefined) {
            this.forecasts = [];
            this.setHeaders(response.data.forecast.labels);
            this.setForecast('variation', response.data.forecast.variations);
            this.setForecast('order', response.data.forecast.orders);
            this.setForecast('driver', response.data.forecast.drivers);
            this.setForecast('miniBreads', response.data.forecast.miniBreads);
            this.setForecast('breads', response.data.forecast.breads);
            this.setForecast('miniSteaks', response.data.forecast.miniSteaks);
            this.setForecast('steaks', response.data.forecast.steaks);
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('report.error'));
        }
        this.loading = false;
      },
      setHeaders (labels) {
        let day = 1;
        this.headers = [{
          text: '',
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'title'
        }].concat(labels.map((label) => {
          return {
            text: label,
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'day' + day++
          };
        }));
      },
      setForecast (name, data) {
        this.forecasts.push({
          title: this.$i18n.t('report.activity.forecast.data.' + String(name) + '.title'),
          day1: data[0],
          day2: data[1],
          day3: data[2],
          day4: data[3]
        });
      }
    }
  };
</script>
