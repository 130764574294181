<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t('report.activity.crowd.table.title') }}
      </v-card-title>
      <v-simple-table v-if="Object.keys(days).length > 0">
        <template #default>
          <thead>
            <tr>
              <th />
              <th>{{ $t('report.activity.crowd.table.column.day') }}</th>
              <th v-for="hour in range()" :key="hour">
                {{ hourToString(hour) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="day in Object.keys(days)" :key="day">
              <td class="text-h3">
                {{ $t('common.time.weekday.' + day) }}
              </td>
              <td>({{ (days[day].share * 100).toFixed(0) }}%)</td>
              <td v-for="hour in range()" :key="hour" class="text-center font-weight-bold" :style="'background: linear-gradient(to top, #6bb9f0 ' + parseInt((days[day].hours[hour].count / days[day].count) * 100) + '%, white 0%);'">
                <span v-if="days[day].hours[hour].share > 0">{{ (days[day].hours[hour].share * 100).toFixed(0) }}%</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else class="text-center">
        {{ $t('report.noresult') }}
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'LocationCrowdAnalytics',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        days: {}
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/location/crowd/week', { timeout: 60000 });
          if (response.data.days !== undefined) {
            this.days = response.data.days;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('report.error'));
        }
      },
      hourToString (hour) {
        if (hour < 10) {
          return '0' + String(hour) + 'h';
        }

        return String(hour) + 'h';
      },
      range () {
        const range = [];
        for (let i = 0; i < 24; i++) {
          let found = false;
          Object.keys(this.days).forEach((weekday) => {
            if (this.days[weekday]?.hours[i]?.share !== undefined && this.days[weekday].hours[i].share > 0) {
              found = true;
            }
          });

          if (found) {
            range.push(i);
          }
        }

        return range;
      }
    }
  };
</script>
